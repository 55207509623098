import { PostgrestError } from "@supabase/supabase-js";
import React from "react";
import { Info } from "react-feather";

export function ErrorAlert({
  error,
}: {
  error: Error | PostgrestError | null;
}) {
  if (!error?.message) return null;

  return (
    <div className="alert alert-error shadow-lg">
      <div>
        <Info />
        <span>{error?.message}</span>
      </div>
    </div>
  );
}
