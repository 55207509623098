import axios from "axios";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export const downloadFile = (blob: Blob, name = "download") => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  // a.style.display = "none";
  a.href = url;
  a.download = name + ".jpg";
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  setTimeout(() => {
    window.close();
  }, 60000);
};

const sleep = (delay: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

export async function download_files(
  files: {
    download: string;
    filename: string;
  }[]
) {
  for (let file of files) {
    const { data: blob } = await axios.get(file.download, {
      responseType: "blob",
    });
    downloadFile(blob, file.filename);
    if (isSafari) {
      await sleep(3000);
    } else {
      await sleep(1500);
    }
  }
}
