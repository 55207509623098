import React, { useState, useContext, useEffect } from "react";
import { Layout } from "../../Layout";
import { supabase } from "../../supabaseClient";
import { AuthContext } from "../../AuthProvider";

// import { schedule_delete } from "../../api/schedule_delete";
import Calendar from "../../components/Calendar";
import { Card } from "../../components/Card/Card";
import { MediaPane } from "./MediaPane";

import "./styles.scss";
import { handleError } from "../../utils/handleError";
import { Post, Posts } from "../../types/api/Post";

export function Planner() {
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const { session } = useContext(AuthContext);
  const [calendarPosts, setCalendarPosts] = useState<Posts | null>([]);

  useEffect(() => {
    getPosts();
  }, [session]);

  const getPosts = async () => {
    let { data: posts, error } = await supabase.from("posts").select(
      `
        *,
        posts_media(*),
        posts_for_feeds(
          *,
          feed(*)
        )
      `
    );
    // .order("posts_media.order", "asc");
    if (error) handleError(error);

    if (!posts) return;

    setCalendarPosts(posts);
  };

  return (
    <Layout>
      <div className="px-8 py-6 flex h-screen items-stretch">
        <div className="media-pane-container">
          <div className="h-full flex flex-col items-stretch">
            <span className="prose">
              <h1 className="mb-4">Files</h1>
            </span>

            <Card noPadding className="h-full mb-0">
              <MediaPane
                updatePosts={getPosts}
                selectedPost={selectedPost}
                setSelectedPost={setSelectedPost}
              />
            </Card>
          </div>
        </div>
        <div className="w-full ml-6 flex flex-col items-stretch">
          <span className="prose">
            <h1 className="mb-4">Planner</h1>
          </span>

          <Card className="h-full mb-0">
            <Calendar
              posts={calendarPosts!}
              setSelectedPost={setSelectedPost}
            />
          </Card>
        </div>
      </div>
    </Layout>
  );
}
