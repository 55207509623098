import { type User, createClient } from "@supabase/supabase-js";
import { handleError } from "./utils/handleError";
import { Database } from "./types/supabase";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient<Database>(supabaseUrl!, supabaseAnonKey!);

let cachedUser: User | null = null;
export async function getUser() {
  if (cachedUser) {
    return cachedUser;
  }

  const {
    data: { user },
    error,
  } = await supabase.auth.getUser();

  if (error) {
    handleError(error);
    return;
  }

  cachedUser = user;
  return user;
}
