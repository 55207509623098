import OneSignal from "react-onesignal";
import { supabase } from "../supabaseClient";

const ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;
// const isDevEnv = process.env.NODE_ENV === "development";
let initialized = false;

export function initOneSignal() {
  if (!initialized) {
    initialized = true;

    OneSignal.init({
      appId: ONESIGNAL_APP_ID!,
      // allowLocalhostAsSecureOrigin: isDevEnv,
    }).then(() => {
      console.log("ONE SIGNAL IS INITIALIZED");
      OneSignal.showSlidedownPrompt().then(() => {
        // do other stuff
      });
    });

    OneSignal.on("subscriptionChange", async function (isSubscribed) {
      console.log("The user's subscription state is now:", isSubscribed);

      if (isSubscribed) {
        const { data } = await supabase.auth.getSession();

        console.log("SETTING ONESIGNAL EXTERNAL ID TO", data.session?.user.id);
        OneSignal.setExternalUserId(data.session?.user.id);
      }
    });
  }
}
