import axios from "axios";
import { getBackend } from "../../utils/getBackend";
import { getAccessTokenHeader } from "../../api/helper/getAccessTokenHeader";
import { ChangeEvent } from "react";

export function LocationSearch() {
  const search = async (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    console.log("Search query", query);

    const { data } = await axios.post(
      getBackend() + "/facebook/pages/search",
      {
        query,
      },
      { headers: await getAccessTokenHeader() }
    );

    console.log("SEARCH RESULTS", data);
  };

  return (
    <div>
      <div className="form-control mb-2">
        <label className="label">
          <span className="label-text">Location</span>
        </label>
        <input
          type="text"
          placeholder="Search a location for a post"
          className="textarea textarea-bordered bg-white w-full"
          onChange={search}
        />
      </div>
    </div>
  );
}
