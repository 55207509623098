import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { supabase } from "./supabaseClient";
import Auth from "./Auth";
import { AuthContext } from "./AuthProvider";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Account from "./Account";
import { Feeds } from "./pages/Feeds/Feeds";
import { NewFeed } from "./pages/Feeds/NewFeed";
import { Planner } from "./pages/Planner/Planner";
import { Post } from "./pages/Post/Post";

import "./index.css";
import "./App.css";
import { Facebook } from "./pages/Auth/Facebook";
import { Threads } from "./pages/Auth/Threads";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Planner />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/posts/:postId",
    element: <Post />,
  },
  {
    path: "/feeds",
    element: <Feeds />,
  },
  {
    path: "/feeds",
    element: <Feeds />,
  },
  {
    path: "/feeds/new",
    element: <NewFeed />,
  },
  {
    path: "/me",
    element: <Account />,
  },
  {
    path: "/auth/facebook/redirect",
    element: <Facebook />,
  },
  {
    path: "/auth/threads/redirect",
    element: <Threads />,
  },
]);

export default function App() {
  const [loading, setLoading] = useState(true);
  const { session, setSession } = useContext(AuthContext);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession?.(session);
      setLoading(false);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession?.(session);
    });
  }, []);

  if (loading) {
    return null;
  }

  return (
    <div className="bg-base-100">
      {!session ? (
        <Auth />
      ) : (
        <>
          <RouterProvider router={router} />
        </>
      )}
    </div>
  );
}
