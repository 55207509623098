import React from "react";
import { Feed } from "../../components/Feed";
import { Wrapper } from "./Wrapper";

export function NewFeed() {
  return (
    <Wrapper active="new">
      <>
        <div className="prose">
          <h1 className="mb-6 mt-10">Add a new feed</h1>
        </div>

        <Feed isNew update={async () => null} />
      </>
    </Wrapper>
  );
}
