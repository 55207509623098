import { User } from "@supabase/supabase-js";
import React, { ReactElement } from "react";
import { useState, createContext } from "react";

interface AuthSession {
  user: User;
}

interface AuthContextType {
  session: AuthSession | null;
  setSession?: React.Dispatch<React.SetStateAction<AuthSession | null>>;
}

export const AuthContext = createContext<AuthContextType>({
  session: null,
});

export const AuthProvider = ({ children }: { children: ReactElement }) => {
  const [session, setSession] = useState<AuthSession | null>(null);
  return (
    <AuthContext.Provider value={{ session, setSession }}>
      {children}
    </AuthContext.Provider>
  );
};
