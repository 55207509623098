import React, { ReactElement } from "react";
import { Layout } from "../../Layout";
import { List, Plus } from "react-feather";
import { Link } from "react-router-dom";
import cx from "classnames";

export function Wrapper({
  children,
  active,
}: {
  children: ReactElement;
  active: string;
}) {
  return (
    <Layout>
      <div className="container mx-auto my-10">
        <div className="flex w-full h-full items-stretch justify-start gap-16">
          <div className="w-72 stati mt-9">
            <ul className="menu gap-2">
              <li
                className={cx(
                  "w-full rounded",
                  active === "feeds" && "bg-slate-200"
                )}
              >
                <Link to="/feeds">
                  <List />
                  Feeds
                </Link>
              </li>
              <li
                className={cx(
                  "w-full rounded",
                  active === "new" && "bg-slate-200"
                )}
              >
                <Link to="/feeds/new">
                  <Plus />
                  New
                </Link>
              </li>
            </ul>
          </div>
          <div className="w-full">{children}</div>
        </div>
      </div>
    </Layout>
  );
}
