import React, { useEffect, useState } from "react";
import { FileUpload } from "../FileUpload";
import { getFiles } from "../../../api/files";
import { handleError } from "../../../utils/handleError";
import { FilePicker } from "../FilePicker";
import { CreatePostModal } from "../CreatePostModal";

import "./styles.scss";
import {
  AddFiles,
  PostSelection,
  SelectedFile,
  SetSelectedPost,
  UpdateFile,
  UpdatePosts,
} from "../../../types/PostSelection";
import { Post } from "../../../types/api/Post";

export function MediaPane({
  updatePosts,
  selectedPost,
  setSelectedPost,
}: {
  updatePosts: UpdatePosts;
  selectedPost: Post | null;
  setSelectedPost: SetSelectedPost;
}) {
  const [files, setFiles] = useState<SelectedFile[]>([]);
  const [selection, setSelection] = useState<PostSelection>({
    openModal: false,
    selected: [],
    update: false,
    step: 1,
  });

  const addFiles: AddFiles = (filesToAdd: SelectedFile[]) => {
    setFiles((f) => [...filesToAdd, ...f]);
  };

  const updateFile: UpdateFile = (name, data) => {
    setFiles((allFiles) =>
      allFiles.map((f) => (f.name === name ? { ...f, ...data } : f))
    );
  };

  const resetSelectedFiles = () => {
    setFiles((allFiles) => allFiles.map((f) => ({ ...f, isSelected: false })));
  };

  useEffect(() => {
    getFiles()
      .then((files) => setFiles(files))
      .catch((err) => handleError(err));
  }, []);

  return (
    <>
      <FileUpload
        addFiles={addFiles}
        updateFile={updateFile}
        isEmpty={files.length === 0}
      >
        <FilePicker
          files={files}
          updateFile={updateFile}
          createPost={(selected) => {
            setSelection((s) => ({
              ...s,
              selected,
              openModal: true,
              update: false,
            }));
          }}
          editMode={selection.update}
        />
      </FileUpload>

      <CreatePostModal
        selection={selection}
        setSelection={setSelection}
        updatePosts={updatePosts}
        selectedPost={selectedPost}
        setSelectedPost={setSelectedPost}
        updateFile={updateFile}
        resetSelectedFiles={resetSelectedFiles}
      />
    </>
  );
}
