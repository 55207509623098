import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { initOneSignal } from "./onesignal/onesignal";
import { Icon, Home, Rss, User } from "react-feather";
import { Toaster } from "react-hot-toast";

export function Layout({ children }: { children: ReactElement }) {
  useEffect(() => {
    initOneSignal();
  }, []);

  return (
    <>
      <Toaster />
      <div className="flex justify-start w-full h-screen relative">
        <div className="bg-white p-4 h-full ">
          <div>
            <a className="btn btn-ghost normal-case text-xl" href="/">
              S
            </a>
          </div>
          <div className="flex-none mt-5">
            <ul className="menu">
              <MenuItem name="Planner" link="/" Icon={Home} />
              <MenuItem name="Feeds" link="/feeds" Icon={Rss} />
              <MenuItem name="Profile" link="/me" Icon={User} />
            </ul>
          </div>
        </div>
        <div className="relative z-0 w-full h-screen overflow-auto">
          {children}
        </div>
      </div>
    </>
  );
}

function MenuItem({
  name,
  link,
  Icon,
}: {
  name: string;
  link: string;
  Icon: Icon;
}) {
  return (
    <li className="tooltip tooltip-right" data-tip={name}>
      <Link to={link} className="rounded-md">
        <Icon className="h-5 w-5" />
      </Link>
    </li>
  );
}
