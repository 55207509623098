import React, { ReactElement } from "react";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import locale from "dayjs/locale/en";
import weekdayPlugin from "dayjs/plugin/weekday";
import objectPlugin from "dayjs/plugin/toObject";
import isTodayPlugin from "dayjs/plugin/isToday";

import "./styles.css";
import { CalendarPost } from "../CalendarPost";
import { Post, Posts } from "../../types/api/Post";

type FormattedDay = {
  day: number;
  month: number;
  year: number;
  isCurrentMonth: boolean;
  isCurrentDay: boolean;
};

const Calendar = ({
  posts,
  setSelectedPost,
}: {
  posts: Posts;
  setSelectedPost: React.Dispatch<React.SetStateAction<Post | null>>;
}) => {
  const now = dayjs().locale({
    ...locale,
  });

  dayjs.extend(weekdayPlugin);
  dayjs.extend(objectPlugin);
  dayjs.extend(isTodayPlugin);

  const [currentMonth, setCurrentMonth] = useState<Dayjs>(now);
  const [arrayOfDays, setArrayOfDays] = useState<{ dates: FormattedDay[] }[]>(
    []
  );

  const nextMonth = () => {
    const plus = currentMonth.add(1, "month");

    setCurrentMonth(plus);
  };

  const prevMonth = () => {
    const minus = currentMonth.subtract(1, "month");

    setCurrentMonth(minus);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM YYYY";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={() => prevMonth()}>
            chevron_left
          </div>
        </div>
        <div className="col col-center">
          <span>{currentMonth.format(dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={() => nextMonth()}>
          <div className="icon">chevron_right</div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "dddd";
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {now.weekday(i).format(dateFormat)}
        </div>
      );
    }
    return <div className="days row">{days}</div>;
  };

  const getAllDays = () => {
    let currentDate = currentMonth.startOf("month").weekday(0);
    const nextMonth = currentMonth.add(1, "month").month();

    let allDates = [];
    let weekDates = [];

    let weekCounter = 1;

    while (currentDate.weekday(0).toObject().months !== nextMonth) {
      const formated = formateDateObject(currentDate);

      weekDates.push(formated);

      if (weekCounter === 7) {
        allDates.push({ dates: weekDates });
        weekDates = [];
        weekCounter = 0;
      }

      weekCounter++;
      currentDate = currentDate.add(1, "day");
    }

    setArrayOfDays(allDates);
  };

  useEffect(() => {
    getAllDays();
  }, [currentMonth]);

  const renderCells = () => {
    const rows: ReactElement[] = [];
    let days: ReactElement[] = [];

    arrayOfDays.forEach((week, index) => {
      week.dates.forEach((d, i) => {
        const allPostInDay = posts.filter((p) => {
          return (
            p.publish_at?.substr(0, 10) ===
            `${d.year}-${(d.month + 1 < 10 ? "0" : "") + (d.month + 1)}-${
              (d.day < 10 ? "0" : "") + d.day
            }`
          );
        });
        days.push(
          <div
            className={`col cell ${
              !d.isCurrentMonth ? "disabled" : d.isCurrentDay ? "selected" : ""
            }`}
            key={i}
          >
            <span className="number">{d.day}</span>
            <div className="p-2 pt-8">
              {allPostInDay?.length > 0 &&
                allPostInDay.map((post) => (
                  <CalendarPost
                    key={post.id + d.day}
                    post={post}
                    setSelectedPost={setSelectedPost}
                  />
                ))}
            </div>
          </div>
        );
      });
      rows.push(
        <div className="row" key={index}>
          {days}
        </div>
      );
      days = [];
    });

    return <div className="body">{rows}</div>;
  };

  const formateDateObject = (date: Dayjs) => {
    const clonedObject = { ...date.toObject() };

    const formatedObject = {
      day: clonedObject.date,
      month: clonedObject.months,
      year: clonedObject.years,
      isCurrentMonth: clonedObject.months === currentMonth.month(),
      isCurrentDay: date.isToday(),
    };

    return formatedObject;
  };

  return (
    <div className="calendar">
      {renderHeader()}
      {renderDays()}
      {renderCells()}
    </div>
  );
};

export default Calendar;
