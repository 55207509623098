import { supabase } from "../supabaseClient";
import axios from "axios";
import { Post } from "../types/api/Post";
import { getAccessTokenHeader } from "./helper/getAccessTokenHeader";
import { getBackend } from "../utils/getBackend";

export async function setup_notifications(task: string, post: Post | null) {
  let error = null;
  const { data } = await supabase.auth.getSession();

  await axios.post(
    getBackend() + "/notifications/" + task,
    {
      task,
      userId: data.session?.user.id,
      postId: post?._id,
      publishAt: post?.publish_at,
      siteUrl: window.location.origin,
    },
    {
      headers: await getAccessTokenHeader(),
    }
  );

  return {
    error,
  };
}
