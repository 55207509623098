import React, {
  ReactElement,
  createRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { X } from "react-feather";

type PropsArg = {
  show: boolean;
  title: string;
  children: ReactElement;
  footer: ReactElement;
  onClose: () => void;
  onSubmit: () => void;
};

const modalRoot = document.getElementById("modal-root");

export function Portal({ children }: { children: ReactElement }) {
  const [elem, setElem] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const node = document.createElement("div");
    modalRoot?.appendChild(node);
    setElem(node);
    return () => {
      modalRoot?.removeChild(node);
    };
  }, []);

  if (!elem) {
    return null;
  }

  return createPortal(children, elem);
}

export function Modal(props: PropsArg) {
  return (
    <Portal>
      <ModalChild {...props} />
    </Portal>
  );
}

function ModalChild({
  show,
  title,
  children,
  footer,
  onClose,
  onSubmit,
}: PropsArg) {
  const modalRef = createRef<HTMLInputElement>();

  const close = useCallback(() => {
    if (modalRef.current) modalRef.current.checked = false;
    onClose();
  }, [modalRef, onClose]);

  useEffect(() => {
    if (modalRef.current) modalRef.current.checked = show;
  }, [show, modalRef]);

  return (
    <>
      <input
        ref={modalRef}
        type="checkbox"
        id="post-modal"
        className="modal-toggle"
      />

      <div className="modal">
        <div className="modal-box bg-white w-11/12 max-w-2xl">
          <form onSubmit={onSubmit}>
            <div className="flex justify-between items-center mb-6">
              <span className="prose">{title}</span>
              <button
                type="button"
                className="btn btn-circle btn-outline btn-sm"
                onClick={close}
              >
                <X />
              </button>
            </div>

            {children}

            <div className="modal-action flex flex-col md:flex-row md:align-middle md:justify-between">
              {footer}
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
