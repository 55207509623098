import React, { ReactElement } from "react";
import { useDropzone } from "react-dropzone";
import cx from "classnames";

import "./styles.scss";
import { Plus } from "react-feather";
import { handleError } from "../../../utils/handleError";
import { uploadFile } from "../../../api/files";
import { AddFiles, UpdateFile } from "../../../types/PostSelection";

export function FileUpload({
  children,
  addFiles,
  updateFile,
  isEmpty,
}: {
  children: ReactElement;
  addFiles: AddFiles;
  updateFile: UpdateFile;
  isEmpty: boolean;
}) {
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/*": [],
      "video/*": [],
    },
    onDrop: async (acceptedFiles) => {
      console.log("FILE DROPPED", acceptedFiles);

      addFiles(
        acceptedFiles.map((file) => ({
          id: Date.now(),
          name: file.name,
          mine_type: file.type,
          url: URL.createObjectURL(file),
          isUploading: true,
        }))
      );

      for (const file of acceptedFiles) {
        try {
          const filename = file.name;
          const uploadedFile = await uploadFile(file);

          console.log("UPLOADED FILE", uploadedFile);

          updateFile(filename, {
            ...uploadedFile,
            isUploading: false,
          });
        } catch (err) {
          handleError(err as Error);
        }
      }

      // REMOVE TEMP URL
      // URL.revokeObjectURL(file.preview)

      console.log("Files Uploaded");
    },
  });

  return (
    <div
      {...getRootProps({
        className: cx(
          "p-10 border-4 rounded-md h-full",
          isDragActive ? "border-blue-400 border-dashed" : "border-transparent"
        ),
      })}
    >
      <button className="btn btn-accent btn-sm btn-outline" onClick={open}>
        <Plus />
        Upload
      </button>
      <input {...getInputProps()} />
      {isEmpty && (
        <p>Drag 'n' drop some files here, or click to select files</p>
      )}
      {children}
    </div>
  );
}
