import { supabase } from "../../supabaseClient";

export async function getAccessTokenHeader() {
  const { data } = await supabase.auth.getSession();

  return {
    "Auth-Access-Token": data.session?.access_token,
    "Auth-Refresh-Token": data.session?.refresh_token,
  };
}
