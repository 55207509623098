import { supabase } from "../supabaseClient";

/**
 * Get Files
 * @returns
 */
export async function getFeeds() {
  const { data, error } = await supabase
    .from("feeds")
    .select("*")
    .order("created_at");

  if (error) {
    throw error;
  }
  return data;
}
