import React, { useEffect, useContext } from "react";
import { AuthContext } from "./AuthProvider";
import { Layout } from "./Layout";
import { supabase } from "./supabaseClient";
import { FieldValues, useForm } from "react-hook-form";

const Account = () => {
  const { session } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getProfile();
  }, [session]);

  const getProfile = async () => {
    try {
      let { data, error, status } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", session?.user?.id!)
        .single();

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        reset(data);
      }
    } catch (error) {
      alert((error as Error).message);
    }
  };

  const updateProfile = async (content: FieldValues) => {
    try {
      const updates = {
        id: session?.user.id,
        ...content,
      };

      let { error } = await supabase.from("profiles").upsert(updates);

      if (error) {
        throw error;
      }
    } catch (error) {
      alert((error as Error).message);
    }
  };

  return (
    <Layout>
      <div className="bg-base-100">
        <div className="container mx-auto my-10">
          <span className="prose">
            <h1 className="mb-8">Profile details</h1>
          </span>

          <div className="card w-full bg-neutral text-primary-content mb-12 drop-shadow-xl">
            <div className="card-body">
              <form onSubmit={handleSubmit(updateProfile)}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Name</span>
                  </label>
                  <input
                    placeholder="Name"
                    {...register("username", { required: true })}
                    className="input input-bordered w-full"
                  />
                  {errors.username && <span>This field is required</span>}
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input
                    placeholder="Email"
                    value={session?.user.email}
                    disabled={true}
                    className="input input-bordered w-full"
                  />
                  {errors.email && <span>This field is required</span>}
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Wesbite</span>
                  </label>
                  <input
                    placeholder="Website"
                    {...register("website")}
                    className="input input-bordered w-full"
                  />
                </div>

                <button type="submit" className="btn btn-primary mt-4">
                  Update Profile
                </button>
              </form>
            </div>
          </div>

          <span className="prose">
            <h1 className="mb-8">API Settings</h1>
          </span>

          <div className="card w-full bg-neutral text-primary-content mb-12 drop-shadow-xl">
            <div className="card-body">
              <form onSubmit={handleSubmit(updateProfile)}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Wordpress Site Url</span>
                  </label>
                  <input
                    placeholder="https://example.com"
                    {...register("wordpress_url")}
                    className="input input-bordered w-full"
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Wordpress API Username</span>
                  </label>
                  <input
                    placeholder="Username"
                    {...register("wordpress_api_username")}
                    className="input input-bordered w-full"
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Wordpress API Password</span>
                  </label>
                  <input
                    placeholder="Password"
                    type="password"
                    {...register("wordpress_api_password")}
                    className="input input-bordered w-full"
                  />
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Wordpress Filebird</span>
                  </label>
                  <input
                    placeholder="API Key"
                    type="password"
                    {...register("wordpress_api_filebird")}
                    className="input input-bordered w-full"
                  />
                </div>

                <button type="submit" className="btn btn-primary mt-4">
                  Update API Settings
                </button>
              </form>
            </div>
          </div>

          <span className="prose">
            <h1 className="mb-8">Sign Out</h1>
          </span>

          <div className="card w-full bg-neutral text-primary-content mb-16 drop-shadow-xl">
            <div className="card-body">
              <button
                type="button"
                className="btn btn-error"
                onClick={() => supabase.auth.signOut()}
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
