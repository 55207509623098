import React from "react";
import cx from "classnames";
import { ImageThumbnail } from "../../../components/ImageThumbnail";
import { SelectedFile, UpdateFile } from "../../../types/PostSelection";

export function FilePicker({
  files,
  updateFile,
  createPost,
  editMode,
}: {
  files: SelectedFile[];
  updateFile: UpdateFile;
  createPost: (value: SelectedFile[]) => void;
  editMode: boolean;
}) {
  const sorted = files
    .filter((f) => f.isSelected)
    .sort((a, b) =>
      a?.selectedAt && b?.selectedAt ? a.selectedAt - b.selectedAt : 0
    );

  return (
    <div className="flex flex-col justify-between h-full py-4">
      <div className="grid grid-cols-4 gap-4">
        {files.map((img) => (
          <ImageThumbnail
            key={img.id + img.name}
            id={img.id!}
            title={img.name}
            url={img.url}
            minetype={img.mine_type}
            isUsed={false}
            isSelected={img.isSelected}
            selectedOrder={
              img.isSelected &&
              sorted.findIndex((f) => f.selectedAt === img.selectedAt) + 1
            }
            isUploading={img.isUploading}
            onSelect={() =>
              updateFile(img.name, {
                isSelected: !img.isSelected,
                selectedAt: Date.now(),
              })
            }
          />
        ))}
      </div>
      <button
        className={cx("btn btn-primary w-full mt-6")}
        disabled={sorted.length === 0}
        onClick={() => createPost(sorted)}
      >
        {editMode ? "Update" : "Create"} a post
      </button>
    </div>
  );
}
