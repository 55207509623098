import React, { ReactElement } from "react";
import cx from "classnames";

export function Card({
  children,
  className,
  noPadding,
}: {
  children: ReactElement;
  className: string;
  noPadding?: boolean;
}) {
  return (
    <div
      className={cx(
        "card w-full bg-neutral text-primary-content mb-8 drop-shadow-sm rounded-md",
        className
      )}
    >
      <div className={cx("card-body", noPadding && " p-0")}>{children}</div>
    </div>
  );
}
