import React, { useContext, useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";
import { Feed } from "../../components/Feed";
import { AuthContext } from "../../AuthProvider";
import { Feeds as FeedsType } from "../../types/api/Feed";
import { Wrapper } from "./Wrapper";
import { handleError } from "../../utils/handleError";

export function Feeds() {
  const { session } = useContext(AuthContext);
  const [feeds, setFeeds] = useState<FeedsType | null>([]);

  useEffect(() => {
    getFeeds();
  }, [session]);

  const getFeeds = async () => {
    let { data: feeds, error } = await supabase
      .from("feeds")
      .select("*")
      .order("created_at");

    if (error) {
      handleError(error);
    }

    setFeeds(feeds);
  };

  return (
    <Wrapper active="feeds">
      <>
        <div className="prose">
          <h1 className="mb-6 mt-10">Existing Social Media Feeds</h1>
        </div>

        {feeds &&
          feeds.map((feed) => (
            <Feed key={feed._id} item={feed} update={getFeeds} />
          ))}
      </>
    </Wrapper>
  );
}
