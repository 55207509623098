import React, { useEffect } from "react";
import axios from "axios";
import { getAccessTokenHeader } from "../../api/helper/getAccessTokenHeader";
import { getBackend } from "../../utils/getBackend";

let called = false;

export function Facebook() {
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    async function runAsync() {
      called = true;
      const { data } = await axios.post(
        getBackend() + "/meta/auth/redirect",
        {
          code: params.get("code"),
          state: params.get("state"),
        },
        { headers: await getAccessTokenHeader() }
      );

      console.log("RESPONSE SUCCESS", data);

      if (data.code === "ok") {
        window.location = "/feeds" as any;
      }
    }
    if (!called) runAsync();
  }, []);

  return (
    <div className="h-screen w-full flex items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        <span className="loading loading-spinner loading-lg text-success"></span>
        <div className="max-w-56">Login you into Facebook</div>
      </div>
    </div>
  );
}
