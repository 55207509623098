import React from "react";
import cx from "classnames";
import "./styles.scss";

export function ImageThumbnail({
  id,
  title,
  url,
  minetype,
  isUsed,
  isSelected,
  selectedOrder,
  isUploading,
  onSelect,
}: {
  id: number;
  title: string;
  url: string;
  minetype: string;
  isUsed?: boolean;
  isSelected?: boolean;
  selectedOrder?: number | false;
  isUploading?: boolean;
  onSelect: () => void;
}) {
  const isVideo = minetype.startsWith("video");

  return (
    <div
      className={cx(
        "thumbnail relative w-20 h-20 tooltip tooltip-accent cursor-pointer",
        (isUploading || isUsed) && " opacity-50"
      )}
      data-tip={title}
      key={id}
      onClick={onSelect}
    >
      {isSelected && (
        <span className="absolute badge badge-primary top-2 right-2 z-10">
          {selectedOrder}
        </span>
      )}
      <div
        className={cx(
          "absolute w-full h-full rounded-lg flex items-center justify-center overflow-hidden",
          isSelected ? "border-4 border-primary" : "hover:bg-contain"
        )}
      >
        {isUploading && (
          <span className="absolute loading loading-spinner loading-lg bg-primary"></span>
        )}

        {!isVideo && (
          <img
            src={url}
            alt=""
            className={cx(
              "object-fill w-full h-full",
              isSelected ? "" : "hover:object-contain"
            )}
          />
        )}

        {isVideo && (
          <video
            src={url}
            className={cx(
              "object-fill w-full h-full",
              isSelected ? "" : "hover:object-contain"
            )}
          />
        )}
      </div>
    </div>
  );
}
