import React, { useState } from "react";
import { Plus } from "react-feather";
import { SavedDescriptions } from "./SavedDecriptions";
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { LocationSearch } from "./LocationSearch";
import { PostType } from "./PostType";
import { TypeMapping } from "../../types/api/Feed";

export function PostCompose({
  title,
  register,
  watch,
  setValue,
  name,
  canAutoPublish,
  postTypes,
  typeMapping,
}: {
  title?: string;
  register: UseFormRegister<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  canAutoPublish?: boolean;
  postTypes: string[];
  typeMapping?: TypeMapping;
}) {
  const fieldName = `${name}.post_content`;
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const updateContent = (addedDescription: string) => {
    setValue(fieldName, watch(fieldName) + addedDescription);
  };

  return (
    <div className="card bg-slate-100 mb-6">
      <div className="card-body py-4 px-6">
        <div className="flex justify-between">
          <div className="flex align-middle gap-4">
            {title && (
              <span className="prose">
                <h3>{title}</h3>
              </span>
            )}

            <PostType
              name={name}
              setValue={setValue}
              watch={watch}
              postTypes={postTypes}
              typeMapping={typeMapping}
            />
          </div>

          {canAutoPublish && (
            <div className="form-control mb-2 flex flex-row items-center justify-start">
              <label className="label w-24" htmlFor={`${name}-auto-publish`}>
                <span className="label-text cursor-pointer">Auto-Publish</span>
              </label>
              <input
                type="checkbox"
                id={`${name}-auto-publish`}
                className="toggle toggle-warning mr-1"
                {...register(`${name}.auto_publish`)}
              />
            </div>
          )}
        </div>

        <div className="form-control mb-2">
          <label className="label">
            <span className="label-text">Post Description</span>
            <span className="label-text-alt">
              {watch(fieldName) ? watch(fieldName).length : 0}
            </span>
          </label>
          <textarea
            placeholder="Write the post description"
            {...register(fieldName)}
            className="textarea textarea-bordered bg-white w-full"
            rows={4}
          />
        </div>

        <button
          type="button"
          className="btn btn-sm btn-ghost gap-2"
          onClick={openModal}
        >
          <Plus />
          Add saved description
        </button>

        <LocationSearch />

        <SavedDescriptions
          open={open}
          close={closeModal}
          updateContent={updateContent}
          name={name}
        />
      </div>
    </div>
  );
}
