import { FieldValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { TypeMapping } from "../../types/api/Feed";

const nameMapping: { [key: string]: string } = {
  PHOTO: "Photo",
  VIDEO: "Video",
  CAROUSEL: "Carousel",
  STORIES: "Stories",
  REEL: "Reel, Short, etc.",
  "STORY-PHOTO": "Story",
  "STORY-VIDEO": "Story",
};

export function PostType({
  name,
  setValue,
  watch,
  postTypes,
  typeMapping,
}: {
  name: string;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  postTypes: string[];
  typeMapping?: TypeMapping;
}) {
  const formKey = `${name}.type`;

  const onSelect = (type: string) => {
    setValue(formKey, type);
  };

  const validTypes = getValidTypes(postTypes, typeMapping);

  if (validTypes.length === 1) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-start gap-2">
        {validTypes?.map((t) => (
          <div className="form-control" key={t.type}>
            <label className="label cursor-pointer items-start justify-center gap-2">
              <input
                type="radio"
                name={formKey}
                className="radio checked:bg-red-500"
                checked={watch(formKey) === t.type}
                onChange={() => onSelect(t.type)}
              />
              <span className="label-text">{t.name}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}

function getValidTypes(postTypes: string[], typeMapping?: TypeMapping) {
  if (!typeMapping) {
    return postTypes?.map((t) => ({
      type: t,
      name: nameMapping[t],
    }));
  }

  const validTypes = postTypes?.filter((t) => typeMapping[t]?.type === t);

  return validTypes.map((t) => ({
    type: typeMapping[t]?.type ?? t,
    name: typeMapping[t]?.name ?? nameMapping[t],
  }));
}
