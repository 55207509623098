import React, { FormEvent, useState } from "react";
import { supabase } from "./supabaseClient";
import cx from "classnames";
import { AuthError } from "@supabase/supabase-js";

export default function Auth() {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setLoading(true);
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: window.location.origin,
        },
      });
      if (error) throw error;
      alert("Check your email for the login link!");
    } catch (error) {
      alert((error as AuthError).cause || (error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="homepage">
      <div className="card bg-white shadow-4xl my-24 max-w-3xl mx-auto">
        <div className="card-body flex items-center">
          <img
            src="Logo128.png"
            alt="Social Media Planner for Photographer logo"
            className="w-24 mb-4"
          />
          <form
            onSubmit={handleLogin}
            className="flex items-center justify-center flex-wrap"
          >
            <input
              id="email"
              className="input input-bordered input-lg w-full mb-4"
              type="email"
              placeholder="Your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className={cx("btn btn-secondary btn-lg", loading && " loading")}
            >
              Sign up or Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
