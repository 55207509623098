import { Format, Output, resize } from "../utils/resizeImage";
import { getUser, supabase } from "../supabaseClient";
import exifr from "exifr";
import { sleep } from "../utils/sleep";

interface FileObject {
  name: string;
  bucket_id: string;
  owner: string;
  id: string;
  updated_at: string;
  created_at: string;
  last_accessed_at: string;
  metadata: Record<string, any>;
  filePath?: string;
}

type FileWithPath = {
  name: string;
  mine_type: string;
  filePath?: string;
  url?: string;
};

export const MEDIA_SIZE = {
  thumbnail: "thumbnail",
  2400: "2400px",
  3600: "3600px",
  original: "original",
  video: "video",
};

const urlCache = new Map();

export async function getSignedUrl(path: string): Promise<string> {
  if (urlCache.has(path)) {
    return urlCache.get(path);
  }

  const { data } = await supabase.storage
    .from("content")
    .createSignedUrl(path, 3600);

  if (!data?.signedUrl) {
    throw new Error("No signed urls for path provided");
  }

  urlCache.set(path, data?.signedUrl);

  return data.signedUrl;
}

/**
 * Get Files
 * @returns
 */
export async function getFiles() {
  const imagePath = await getMediaPath(MEDIA_SIZE.thumbnail);
  const { data: images, error: imageErrors } = await supabase.storage
    .from("content")
    .list(imagePath, {
      limit: 100,
      offset: 0,
      sortBy: { column: "created_at", order: "desc" },
    });

  if (imageErrors) {
    throw imageErrors;
  }

  const allContent = images.map((f) => ({
    ...f,
    filePath: `${imagePath}/${f.name}`,
  }));
  const filesWithUrls = await addSignedUrls(allContent, MEDIA_SIZE.thumbnail);

  return filesWithUrls.map((f) => ({
    _id: f.id,
    name: f.name,
    url: f.url,
    mine_type: f.metadata.mimetype,
  }));
}

/**
 * Upload Files
 */
export async function uploadFile(file: File) {
  if (file.type.startsWith("image/")) {
    const exif = await exifr.parse(file);

    await supabase.from("media_metadata").insert({
      path: (await getMediaPath(MEDIA_SIZE.original)) + "/" + file.name,
      metadata: exif,
    });

    await resizeAndUploadImage(2400, MEDIA_SIZE[2400], file);
    await resizeAndUploadImage(3600, MEDIA_SIZE[3600], file);
    await uploadFileToStorage(MEDIA_SIZE.original, file);

    return resizeAndUploadImage(
      240,
      MEDIA_SIZE.thumbnail,
      file,
      false /* skipSignedUrl */
    );
  } else if (file.type.startsWith("video/")) {
    console.log("Uploading video", file);

    const uploadedVideoFile = await uploadFileToStorage(MEDIA_SIZE.video, file);

    const thumbnail: File = await new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const videoElem = document.createElement("video");
      videoElem.crossOrigin = "anonymous";

      canvas.style.position = "fixed";
      canvas.style.top = "0";
      canvas.style.zIndex = "-100";
      videoElem.style.position = "fixed";
      videoElem.style.zIndex = "-100";
      videoElem.style.top = "0";

      document.body.appendChild(videoElem);
      document.body.appendChild(canvas);

      videoElem.addEventListener("loadedmetadata", async function () {
        canvas.width = videoElem.videoWidth;
        canvas.height = videoElem.videoHeight;

        await sleep(4);

        //setTimeout(() => {
        canvas
          .getContext("2d")
          ?.drawImage(
            videoElem,
            0,
            0,
            videoElem.videoWidth,
            videoElem.videoHeight
          );

        console.log("canvas write image");

        await sleep(4);

        canvas.toBlob(
          (blob) => {
            if (!blob) return reject("Error");
            const file = new File(
              [blob],
              uploadedVideoFile.name + ".video-thumbnail.jpg",
              {
                type: "image/jpeg",
              }
            );
            resolve(file);
          },
          `image/jpeg`,
          0.9
        );
        //}, 3000);
      });

      videoElem.src = uploadedVideoFile.url!;
    });

    return resizeAndUploadImage(
      240,
      MEDIA_SIZE.thumbnail,
      thumbnail,
      false /* skipSignedUrl */
    );
  }
}

async function resizeAndUploadImage(
  maxSize: number,
  pathSize: string,
  file: File,
  skipSignedUrl = true
) {
  const newFile = await resize(file, {
    maxWidth: maxSize,
    maxHeight: maxSize,
    quality: 0.9,
    output: Output.File,
    format: Format.jpeg,
  });
  return uploadFileToStorage(pathSize, newFile, skipSignedUrl);
}

async function uploadFileToStorage(
  size: string,
  file: File,
  skipSignedUrl = false
) {
  const path = await getMediaPath(size);
  const filePath = `${path}/${file.name}`;

  const newFile: FileWithPath = {
    name: file.name,
    mine_type: file.type,
  };

  const { data, error } = await supabase.storage
    .from("content")
    .upload(filePath, file, {
      cacheControl: "3600",
      upsert: false,
    });

  if (error) {
    throw error;
  }

  newFile.filePath = data.path;

  if (!skipSignedUrl) {
    newFile.url = await getSignedUrl(filePath);
  }

  return newFile;
}

async function addSignedUrls(files: FileObject[], size: string) {
  if (!files.length) return [];

  const urls = files.map((f) => f.filePath!);

  const { data, error } = await supabase.storage
    .from("content")
    .createSignedUrls(urls, 3600);

  if (error) {
    throw error;
  }

  return files.map((f, i) => ({
    ...f,
    url: data[i].signedUrl,
    filePath: f.name,
  }));
}

export async function getMediaPath(size = MEDIA_SIZE.original) {
  const user = await getUser();
  return `${user?.id}/media/${size}`;
}

export async function getMediaPathWithFilename(size: string, name: string) {
  return (await getMediaPath(size)) + "/" + name;
}
