import { FieldValues } from "react-hook-form";
import { supabase } from "../supabaseClient";
import { MEDIA_SIZE, getMediaPathWithFilename } from "./files";
import { setup_notifications } from "./setup_notification";
import { Feeds } from "../types/api/Feed";
import { SelectedFile } from "../types/PostSelection";

export async function schedule(
  feeds: Feeds,
  values: FieldValues,
  selected: SelectedFile[]
) {
  let error = null;
  const {
    data: { session },
  } = await supabase.auth.getSession();

  const { data: post, error: postError } = await supabase
    .from("posts")
    .insert({
      type: values.default.type,
      publish_at: new Date(values.date).toUTCString(),
      user_id: session?.user.id,
    })
    .select()
    .limit(1)
    .single();

  error = postError;

  const imgs = Object.values(selected).sort((a, b) =>
    a?.order && b?.order ? a.order - b.order : 0
  );

  const media = [];

  for (const img of imgs) {
    const isVideo = img.name.endsWith(".video-thumbnail.jpg");
    let filename = img.name;
    let mimetype = img.mine_type;

    if (isVideo) {
      filename = img.name.replace(".video-thumbnail.jpg", "");
      const { data: videoDetails } = await supabase.storage
        .from("content")
        .list(`${session?.user.id}/media/video`, { search: filename });

      console.log("VIDEO", filename, videoDetails);

      mimetype = videoDetails![0].metadata.mimetype;
    }

    media.push({
      post_id: post?.id,
      post: post?.id,
      title: img.name,
      src_thumbnail: await getMediaPathWithFilename(
        MEDIA_SIZE.thumbnail,
        img.name
      ),
      src_2400: await getMediaPathWithFilename(
        isVideo ? MEDIA_SIZE.video : MEDIA_SIZE[2400],
        filename
      ),
      src_3600: await getMediaPathWithFilename(
        isVideo ? MEDIA_SIZE.video : MEDIA_SIZE[3600],
        filename
      ),
      src_original: await getMediaPathWithFilename(
        isVideo ? MEDIA_SIZE.video : MEDIA_SIZE.original,
        filename
      ),
      mine_type: mimetype,
      user_id: session?.user.id,
      order: img.order,
    });
  }

  await supabase.from("posts_media").insert(media);

  const postsForFeeds = Object.values(feeds).map((feed) => ({
    post_id: post?.id,
    post: post?.id,
    feed_id: feed.id,
    feed: feed.id,
    content: values[feed.slug!].post_content,
    auto_publish: !!values[feed.slug!].auto_publish,
    type: values[feed.slug!].type,
    user_id: session?.user.id,
  }));

  await supabase.from("posts_for_feeds").insert(postsForFeeds);

  await setup_notifications("create", post);

  return {
    error,
  };
}
