import React, { ReactElement, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { supabase } from "../../supabaseClient";
import { ErrorAlert } from "../ErrorAlert";
import { Trash2 } from "react-feather";
import { SavedContent, SavedContents } from "../../types/api/SavedContent";
import { PostgrestError } from "@supabase/supabase-js";

export function SavedDescriptions({
  open,
  close,
  updateContent,
  name,
}: {
  open: boolean;
  close: () => void;
  updateContent: (content: string) => void;
  name: string;
}) {
  const [savedContent, setSavedContent] = useState<SavedContents | null>([]);
  const [selected, setSelected] = useState<SavedContent | null>(null);
  const [apiError, setApiError] = useState<PostgrestError | null>(null);

  useEffect(() => {
    getSavedContent();
  }, []);

  const getSavedContent = async () => {
    const { data, error } = await supabase.from("saved_content").select();
    setApiError(error);
    setSavedContent(data);
    setSelected(null);
  };

  const edit = (description: SavedContent) => {
    setSelected(description);
  };

  const addContentToDescription = (content: SavedContent["content"]) => {
    updateContent(content!);
    close();
  };

  return createPortal(
    <>
      <input
        type="checkbox"
        id={`saved-content-modal-${name}`}
        className="modal-toggle"
        checked={open}
        readOnly
      />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-md">
          <span className="prose">
            <h3 className="font-bold mb-2">Saved Descriptions</h3>
          </span>

          <ErrorAlert error={apiError} />

          {savedContent &&
            savedContent.map((desc) =>
              selected && selected.id === desc.id ? (
                <CardContainer key={desc.id}>
                  <NewOrEdit
                    id={desc.id}
                    name={desc.name!}
                    content={desc.content}
                    setApiError={setApiError}
                    onUpdate={getSavedContent}
                  />
                </CardContainer>
              ) : (
                <CardContainer key={desc.id}>
                  <>
                    <span className="prose">
                      <h3>{desc.name}</h3>
                    </span>
                    <textarea
                      value={desc.content!}
                      className="textarea textarea-bordered w-full mb-2"
                      rows={2}
                      disabled
                    />

                    <div className="flex gap-2 w-full">
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={() => addContentToDescription(desc.content)}
                      >
                        Add description
                      </button>
                      <button
                        className="btn btn-ghost btn-sm"
                        onClick={() => edit(desc)}
                      >
                        Edit
                      </button>
                    </div>
                  </>
                </CardContainer>
              )
            )}

          <CardContainer>
            <NewOrEdit setApiError={setApiError} onUpdate={getSavedContent} />
          </CardContainer>

          <div className="modal-action">
            <button type="button" className="btn" onClick={close}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("saved-content-modal")!
  );
}

function CardContainer({ children }: { children: ReactElement }) {
  return (
    <div className="card bg-slate-200 mb-4">
      <div className="card-body p-4">{children}</div>
    </div>
  );
}

function NewOrEdit({
  name,
  content,
  id,
  setApiError,
  onUpdate,
}: {
  name?: string;
  content?: SavedContent["content"];
  id?: number;
  setApiError: (err: PostgrestError | null) => void;
  onUpdate: () => Promise<void>;
}) {
  const { register, getValues, reset } = useForm({
    defaultValues: {
      name: id ? name : null,
      content: id ? content : null,
    },
  });

  const onSubmit = async () => {
    const values = getValues();

    const { data: session } = await supabase.auth.getSession();

    const newContent: SavedContent = {
      name: values.name!,
      content: values.content!,
      user_id: session.session?.user.id!,
      id: id!,
      created_at: "",
    };

    const { error } = await supabase.from("saved_content").upsert(newContent);

    setApiError(error);

    reset();
    onUpdate();
  };

  const deleteDesc = async () => {
    await supabase.from("saved_content").delete().eq("id", id!);
    await onUpdate();
  };

  return (
    <form>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Name</span>
        </label>
        <input
          {...register("name", { required: true })}
          className="input input-bordered w-full mb-2"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Description</span>
        </label>
        <textarea
          {...register("content")}
          className="textarea textarea-bordered w-full mb-2"
          rows={4}
        />
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={onSubmit}
        >
          {!id ? "Add Saved Description" : "Update"}
        </button>
        {id && (
          <button
            type="button"
            className="btn btn-ghost btn-sm"
            onClick={deleteDesc}
          >
            <Trash2 />
          </button>
        )}
      </div>
    </form>
  );
}
