import React, { useContext, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import cx from "classnames";
import { supabase } from "../../supabaseClient";
import { AuthContext } from "../../AuthProvider";
import { Feed as FeedType } from "../../types/api/Feed";
import { handleError } from "../../utils/handleError";
import axios from "axios";
import { getAccessTokenHeader } from "../../api/helper/getAccessTokenHeader";
import { getBackend } from "../../utils/getBackend";

export const Feed = (props: {
  item?: FeedType;
  isNew?: boolean;
  update: () => void;
}) => {
  const { item, isNew, update } = props;
  const { session } = useContext(AuthContext);
  const [apiError, setApiError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);

  // try {
  //   if (item) item.tokens = JSON.stringify(item.tokens);
  // } catch (e) {
  //   // no-op
  // }

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // @ts-expect-error
  } = useForm({
    values: isNew ? undefined : item,
    defaultValues: isNew ? undefined : item,
  });

  const onSubmit: SubmitHandler<FieldValues> = async (content) => {
    setUpdating(true);
    setApiError(null);

    const formatted = {
      name: content.name,
      tokens: content.tokens,
      auto_publish: content.auto_publish,
      webhook: content.webhook,
      client_id: content.client_id,
      app_secret: content.app_secret,
      user_id: session?.user.id,
    };

    if (content.max_length === "") {
      delete content.max_length;
    }

    const { data, error } = isNew
      ? await supabase.from("feeds").insert(formatted).select()
      : await supabase
          .from("feeds")
          .update(formatted)
          .eq("id", item?.id!)
          .select();

    if (error) {
      handleError(error);
    }

    if (!data) return;

    const result = data[0];

    setApiError(apiError);

    update();

    if (isNew) {
      reset();
    }

    setUpdating(false);
  };

  const deleteItem = async () => {
    setDeleting(true);
    setApiError(null);

    await supabase.from("feeds").delete().eq("id", item?.id!);

    setApiError(apiError);

    update();
    setDeleting(false);
  };

  const facebookLogin = async () => {
    const {
      data: { redirect },
    } = await axios.post(
      getBackend() + "/meta/auth/login",
      {},
      { headers: await getAccessTokenHeader() }
    );

    window.location = redirect;
  };

  const refreshIds = async () => {
    const { data } = await axios.post(
      getBackend() + "/meta/app-ids/refresh",
      null,
      {
        headers: await getAccessTokenHeader(),
      }
    );
    console.log("IDs refeshed", data);
  };

  const threadsLogin = async () => {
    const {
      data: { redirect },
    } = await axios.post(
      getBackend() + "/threads/auth/login",
      {},
      { headers: await getAccessTokenHeader() }
    );

    window.location = redirect;
  };

  return (
    <div className="card w-full bg-neutral text-primary-content mb-8 drop-shadow-xl">
      <div className="card-body">
        {!isNew && <h2 className="card-title my-0">{item?.name}</h2>}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex gap-4 items-start">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Name</span>
              </label>
              <input
                placeholder="Name"
                {...register("name", { required: true })}
                className="input input-bordered w-full"
              />
              {errors.name && <span>This field is required</span>}
            </div>

            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Slug</span>
              </label>
              <input
                placeholder="slug"
                {...register("slug", { required: true })}
                className="input input-bordered w-full"
              />
              {errors.name && <span>This field is required</span>}
            </div>
          </div>

          <div className="form-control">
            <label className="label justify-start gap-2">
              <input
                placeholder="auto_publish"
                type="checkbox"
                {...register("auto_publish")}
                className="checkbox"
              />
              <span className="label-text">Auto publish</span>
            </label>
          </div>

          <div tabIndex={0} className="collapse collapse-plus p-0 m-0">
            <input type="checkbox" className="peer" />
            <div className="collapse-title text-md font-medium px-0">
              Advanced
            </div>
            <div className="collapse-content p-0">
              <div className="columns-2">
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Auth Tokens (JSON)</span>
                  </label>
                  <textarea
                    placeholder="tokens"
                    {...register("tokens")}
                    className="textarea textarea-bordered  w-full"
                  />
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Webhook</span>
                  </label>
                  <input
                    placeholder="webhook"
                    {...register("webhook")}
                    className="input input-bordered w-full"
                  />
                </div>
              </div>

              {item?.slug === "facebook_api" && (
                <div className="my-6 flex gap-4">
                  <button
                    type="button"
                    onClick={facebookLogin}
                    className="btn btn-outline"
                  >
                    Get Facebook Access Token
                  </button>

                  <button
                    type="button"
                    onClick={refreshIds}
                    className="btn btn-ghost"
                  >
                    Refresh Facebook Page and Instagram Account IDs
                  </button>
                </div>
              )}

              {item?.slug === "threads_api" && (
                <div className="my-6 flex gap-4">
                  <button
                    type="button"
                    onClick={threadsLogin}
                    className="btn btn-outline"
                  >
                    Get Threads Access Token
                  </button>
                </div>
              )}
            </div>

            <div className="mt-4 flex gap-4">
              <button
                type="submit"
                className={cx("btn btn-primary", updating && " loading")}
              >
                {isNew ? "Add" : "Update"}
              </button>

              {!isNew && (
                <button
                  className={cx("btn btn-error", deleting && " loading")}
                  onClick={deleteItem}
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
